import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "usage",
      "style": {
        "position": "relative"
      }
    }}>{`Usage`}</h2>
    <p>{`WideLists represent a set of related WideListItems in a list view, which
potentially are actionable. The component has a variety of options that can suit
your needs.`}</p>
    <h2 {...{
      "id": "options",
      "style": {
        "position": "relative"
      }
    }}>{`Options`}</h2>
    <h2 {...{
      "id": "default-item",
      "style": {
        "position": "relative"
      }
    }}>{`Default item`}</h2>
    <p>{`The default WideListItem consists out of:`}</p>
    <ul>
      <li parentName="ul">{`1: Item Title`}</li>
      <li parentName="ul">{`2: Item description (optional)`}</li>
    </ul>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=856%3A176" mdxType="FigmaEmbed" />
    <p>{`Both the item title and the item description text styles (font-family, font
size, font-weight, color) are customizable for specific use cases. Please
consult `}<a parentName="p" {...{
        "href": "/foundations/fonts"
      }}>{`Chameleon's Typographic System`}</a>{` for the
possibilities and guidelines.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=861%3A26" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "actions",
      "style": {
        "position": "relative"
      }
    }}>{`Actions`}</h2>
    <p>{`Each WideListItem has the possibilty to add actions:`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=863%3A68" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "icons--avatars",
      "style": {
        "position": "relative"
      }
    }}>{`Icons & Avatars`}</h2>
    <p>{`An `}<a parentName="p" {...{
        "href": "/components/icon/code"
      }}>{`Icon`}</a>{` or `}<a parentName="p" {...{
        "href": "/components/avatar/code"
      }}>{`Avatar`}</a>{` can be
added to convey more meaning.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Icon`}</inlineCode>{` is always designed with a 24px x 24px default size. The color of the
Icon can be changed with one of `}<a parentName="p" {...{
        "href": "/foundations/color"
      }}>{`Chameleon's Color Palette`}</a>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`Avatar`}</inlineCode>{` can be displayed in a squared or circular appearance.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=865%3A685" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "clickable",
      "style": {
        "position": "relative"
      }
    }}>{`Clickable`}</h2>
    <p>{`A WideListItem can be clickable if it has an href or button property. Clickable
items visually differences by adding a chevron arrow indicating they can be
interacted with.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem
    title="Item title"
    description="Item description"
    clickable
    as="a"
    href="#test"
  />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "size",
      "style": {
        "position": "relative"
      }
    }}>{`Size`}</h2>
    <p>{`The default behaviour of the WideList is that it takes the 100% available width.
On larger screens the item has a larger surrounding padding size than on mobile.
This ensures that the items will be displayed in a more compact and dense
manner. Please respect this behaviour when designing with the WideList
component.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=864%3A1601" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "text-wrap",
      "style": {
        "position": "relative"
      }
    }}>{`Text wrap`}</h2>
    <p>{`When the length of the content exceeds the availbale horizontal space, the text
wraps to multiple lines.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=885%3A28" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "loading-state",
      "style": {
        "position": "relative"
      }
    }}>{`Loading state`}</h2>
    <p>{`The component has a built-in loading state that can be enabled to provide the
user with useful feedback when data is being loaded.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<WideList>
  <WideListItem loading={true} />
</WideList>
`}</code></pre>
    <h2 {...{
      "id": "composition-of-multiple-items",
      "style": {
        "position": "relative"
      }
    }}>{`Composition of multiple items`}</h2>
    <p>{`Each `}<inlineCode parentName="p">{`WideListItem`}</inlineCode>{` should be wrapped in a `}<inlineCode parentName="p">{`WideList`}</inlineCode>{` container, where the items
are seperated by a `}<inlineCode parentName="p">{`Divider`}</inlineCode>{`. The container can be displayed in a flat or
elevated appearance. A WideList with an elevation always has a border-radius of
`}<inlineCode parentName="p">{`space-2`}</inlineCode>{` (4px) and a box-shadow of `}<inlineCode parentName="p">{`shadow-2`}</inlineCode>{`.`}</p>
    <FigmaEmbed url="https://www.figma.com/file/eVuNlIBZh5wbDiM4blDaEb/Chameleon-Best-Practices?node-id=885%3A922" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "related-components",
      "style": {
        "position": "relative"
      }
    }}>{`Related components`}</h2>
    <ul>
      <li parentName="ul">{`When you're looking to create a list of tabular data, use the
`}<a parentName="li" {...{
          "href": "/components/table/code"
        }}>{`Table`}</a>{` component.`}</li>
      <li parentName="ul">{`When you're looking to create a ordered/un ordered list to make content more
readable, use the `}<a parentName="li" {...{
          "href": "/components/list/code"
        }}>{`List`}</a>{` component.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      